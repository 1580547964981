import {Link} from "react-router-dom";

import "./Navbar.css";


const Navbar = () => {
    return (
        <main>
            <div className="header-contents">
                <h2 className="header-text">Portfolio Robin Schoenmaker</h2>
            </div>
            <div className="header-main">
                <a href="/"><i className="fa-light fa-code-fork"></i></a>
                <ul className="header-links">
                    <li className="header-link"><Link to="/"> Home</Link></li>
                    <li className="header-link"><Link to="/Portfolio"> Portfolio</Link></li>
                    <li className="header-link"><Link to="/stage_blog"> Stage Blog</Link></li>
                </ul>
            </div>

        </main>
    );
}

export default Navbar;