import "./Usercard.css";

const Usercard = () =>{
    return(
        <section className="section-usercard">

            <div className="div-usercard">
                <div className="links-img">
                    <img className="img-robin" target="_blank"   src="/img/robin_schoenmaker.jpg" alt="Robin Schoenmaker"/>
                </div>
                <article className="article-usercard">
                    <h1 className="text-card-tittle">Robin Schoenmaker</h1>
                    <p className="text-card-about">My name is Robin Schoenmaker, A developer from the Netherlands located in Alkmaar and currently still studying at the Media College Amsterdam. Currently,
                        I'm focussing on learning ReactJS, Laravel, SQL, CSS and Tailwindcss. After my internship, I'd like to start  as a front-end developer and continue learning
                        back-end, so I can become a full-stack developer.
                    </p>
                </article>
                <div className="item-icons">
                    <a href="https://github.com/Streats22" target="_blank" rel="noreferrer" alt="Github">
                        <i className="fa-brands fa-github item-icon"></i></a>
                    <a href="https://www.linkedin.com/in/robin-schoenmaker-77268b173/" rel="noreferrer" target="_blank" alt="Linkedin">
                        <i className="fa-brands fa-linkedin item-icon"></i></a>
                    <a href="mailto:robin.schoenmaker@outlook.com" rel="noreferrer" alt="Mail">
                        <i className="fa-solid fa-envelope item-icon"></i></a>
                </div>
            </div>
        </section>
    )
}

export default Usercard;