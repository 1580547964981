import "./Home.css";
import Usercard from "../Compontens/usercard/Usercard";
import {Link} from "react-router-dom";


const Home = () =>{
    return(
        <>
            <section className="container-home">
                <h2 className="text-home ">Portfolio Robin Schoenmaker<br/>
                    Made with REACT
                    <br/>
                    Scroll to see more
                </h2>
                <Link to="/Portfolio"> <button className="portfolio-work">Portfolio work</button></Link>
            </section>

            <Usercard />
        </>

    );
}

export default Home;