
import "./Footer.css";


const Footer = () =>{
    return(
        <nav>
            <div className="footer-main">

                <h1>Copyrighted by Robin Schoenmaker and StreatsDesign</h1>
            </div>
            <div className="icons-footer">
               <a href="https://github.com/Streats22" target="_blank" rel="noreferrer" > <i className="fa-brands fa-github"></i></a>
                <a href="mailto:robin.schoenmaker@outlook.com" ><i className="fa-solid fa-envelope"></i></a>
                <a href="https://www.linkedin.com/in/robin-schoenmaker-77268b173/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i> </a>
            </div>

        </nav>
    );
}
export default Footer;