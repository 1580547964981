import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Portfolio from "./Pages/Portfolio";
import {PortfolioDetail} from "./Pages/PortfolioDetail";
import Home from "./Pages/Home";
import Stageblog from "./Pages/Stageblog";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/Portfolio",
                element: <Portfolio/>
            },
            {
                path: "/Portfolio/:technologyId/:projectId",
                element: <PortfolioDetail/>
            },{
                path: "/stage_blog",
                element: <Stageblog/>
            },
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
