import "./Stageblog.css";
const Stageblog = () =>{
    return(
       <>
        <section className="Stage-info">
            <h1>StageBlog Coming soon..</h1>
        </section>
       </>
    );

}
export default Stageblog