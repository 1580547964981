import {useParams} from/**/ "react-router-dom";
import {technologies} from "../project-data";
import "./PortfolioDetail.css";

export const PortfolioDetail = () => {
    // this 'id' corresponds to the ':id' in the route in line 25 of index.js
    let {technologyId, projectId} = useParams();

    const technology = technologies.find(technology => technology.id === technologyId);
    const project = technology.projects.find(project => project.id === projectId);

    const {title, summary, images, github} = project;

    return (
        <>
            <section className="portfolio-details-section">
                <h1 className="Tittle-Details">{title}</h1>

               <p className="text1"> {summary}</p>
                <div className="github">
                    <a href={github} target="_blank" rel="noreferrer" alt="Github">
                        <i className="fa-brands fa-github item-icon"></i></a>
                </div>
            </section>
            {images.map(image =>
                <img className="test" src={image} alt=""/>
            )}
        </>
    )
}
