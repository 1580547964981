import PortfolioItem from "../Compontens/portoflioItem/PortfolioItem";
import "./Portfolio.css";
import {technologies} from "../project-data";


const Portfolio = () => {
    return (
        <main className="Portfolio">
            <div className="info-field-pf text-image">The projects listed below are from professional work to school
                work.<br/>
                The works and code shown in this portfolio is written by me or in collaboration with someone else.
            </div>

            <ol>
            {technologies.map(technology => (
                <li key={technology.id}>
                    <h1 className="text-field">
                        {technology.technologyName}
                        <i className={`fa-brands ${technology.icon}`} />
                    </h1>
                    <section className="portfolio-body"> ]
                        <ol className="ol-portfolio-contents">

                        {technology.projects.map(project => (
                            <li key={project.id} className="li-portfolio-items">
                                <PortfolioItem title={project.title} text="" image={project.image} projectId={project.id} technologyId={technology.id}>
                                {project.details}
                            </PortfolioItem>
                            </li>
                        ))}

                        </ol>
                    </section>
                </li>
            ))}
            </ol>
        </main>
    );
}
export default Portfolio;
