import './App.css';
import Navbar from "./Compontens/Navbar/Navbar";
import Footer from "./Compontens/Footer/Footer";
import {Outlet} from "react-router-dom";


export const App = () => {
    return (
        <div className="app-container">
            <Navbar/>
            <Outlet/>
            <Footer/>
        </div>
    );
}

export default App;
