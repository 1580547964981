import "./PortfolioItem.css";
import { Link } from "react-router-dom";



const PortfolioItem = ({children, ...props}) => {
    let PortfolioImage = <img src={"/img/" + props.image ?? 'GLUE-Project.jpeg'} alt="GLUE"/>

    const { projectId, technologyId } = props;

    return (
        <main className="main-item-container">
            <article>
                <figure className="figure-card">
                    {PortfolioImage}
                </figure>
                <header>
                    <h2> {props.title || "Placeholder Title"}</h2>
                </header>
                <section className="info-section">
                    <span>{children ||  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat "}</span>
                </section>
                <div className="button-section">
                    <Link to={`/Portfolio/${technologyId}/${projectId}`} className="readmore-button">Read more...</Link>
                </div>
            </article>
        </main>
    );
}
export default PortfolioItem;
