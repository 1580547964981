import "./Pages/PortfolioDetail.css";

export const reactProjects = [
    {
        id: "Coffeez",
        title: "Coffeez - School",
        github: "https://github.com/Streats22/Reactlessons/tree/master/coffeez",
        image: "./projects/Coffeez/Coffeez_banner.png",
        images: [
            "/img/projects/Coffeez/Coffeez_Style.png",
            "/img/projects/Coffeez/Coffeez_code1.png",
            "/img/projects/Coffeez/Coffeez_code.png"

        ],
        details: <p><b>Coffeez</b> was one of the first React projects made at school to show us the basics of react and how you can use this to make amazing stuff!</p>
            ,
        summary: <span>Coffeez was the first project made with react although its a basic site and some basic code to make us realize the power REACT has to give and use.</span>,

        // ...
    },
    {
        id: "Countercard",
        title: "Countercard - School",
        github: "https://github.com/Streats22/Reactlessons/tree/master/minicounter",
        image: "./projects/Countercard/countercard_example.png",
        images: [
            "/img/projects/Countercard/Countercard_showoff.gif",
            "/img/projects/Countercard/countercard_code.png",
        ],
        details: <span><b>The Countercard</b>, the name speaks for itself its a card on which you can add and detract a single number from the total shown in the card. </span>,
        summary: <span>The countercard is a card with a simple function, if you click increase the number on the card you click goes up by +1 and when you click decrease the number of the card goes down by -1.
            The more complicated part of this project was to seperate the counting element to make it increase and decrease each card separately and not all with just 1 button.
            <br/>this took around 1 week to make maximum.
        </span>,
        // ...
    },

    {
        id: "Musiclist",
        title: "Musiclist - School",
        github: "https://github.com/Streats22/Reactlessons/tree/master/mijn-muzieklijst",
        image: "./projects/Musiclist/musiclist_home.png",
        images: [
            "/img/projects/Musiclist/musiclist_Code_showoff.gif",
            "/img/projects/Musiclist/musiclist_code1.png",
            "/img/projects/Musiclist/musiclist_code2.png",
            "/img/projects/Musiclist/musiclist_code3.png",
            "/img/projects/Musiclist/musiclist_code4.png",
            "/img/projects/Musiclist/musiclist_code5.png",
        ],
        details: <span><b>The Musiclist</b> was a project based on the standard welkom to react code The Todo list, The musiclist expands on that and makes it a broader project. </span>,
        summary: <><span><b>The Musiclist</b> was a project based on the standard welkom to react code The Todo list, The musiclist expands on that and makes it a broader project.
            with the code written here the project works by adding in info with enter or the left mouseclick, the props can be used to re-use this project list to make it for more genres.
            This project was made within a 2 weeks.
        </span></>,
        // ...
    },


];

export const laravelProjects = [
    {
        id: "Laravel_portfolio_project",
        title: "Laravel Portfolio project",
        github: "https://github.com/Streats22/Keuzedeel-interactionDesign-StreatsDesign/tree/main/StreatsDesign/Portoflio_SD/Portfolio_page",
        image: "./projects/StreatsdesignPortoflio/Laravel_site1.png",
        images: [
            "/img/projects/StreatsdesignPortoflio/Laravel_site2.png",
            "/img/projects/StreatsdesignPortoflio/Laravel_site3.png",
            "/img/projects/StreatsdesignPortoflio/Laravel_site4.png",
            "/img/projects/StreatsdesignPortoflio/Laravel_site5.png"
        ],
        details: <span>This project was based upon a school assignment, we where requested to make a website for yourself or a portfolio. I ended up making this site as a possible portfolio website for my own company.</span>,
        summary: <><span>This project was based upon a school assignment, we where requested to make a website for yourself or a portfolio. I ended up making this site as a possible portfolio website for my own company.
        The company "streatsdesign" was made by me Robin Schoenmaker and made to attract more customers <br/> <br/>
            This project was made in the time span of around 2 months and isn't finsihed yet due to us not finishing the laravel lessons more in debt.

        </span></>,
        // ...
    }

];

export const wordPressProjects = [
    {
        id: "glue-amsterdam",
        title: "GLUE-AMSTERDAM",
        github: "https://github.com/krihs0/Glue",
        image: "./GLUE-Project.jpeg",
        images: [
            "/img/projects/GLUE/About_GLUE1.png",
            "/img/projects/GLUE/code_GLUE1.png",
            "/img/projects/GLUE/code_GLUE2.png",
            "/img/projects/GLUE/code_GLUE3.png"
        ],
        details: <span><b>GLUE</b> is a three-day design-route from and for Amsterdam designers, the general public,
                    architects, brands, showrooms, galleries,
                    academies, and other colleagues.
                    GLUE amsterdam connected by design connects
                    all locations and provides a substantive and attractive program for colleagues and the public</span>,
        summary: <><span>About <b>GLUE </b>
A three-day design-tour for Amsterdam designers, the general public, architects, brands, showrooms, galleries, academies and other colleagues.
GLUE amsterdam connected by design connects all locations and provides a substantive and attractive program for colleagues and the public.
Independence is essential, GLUE does not commit to one party, but all of them. Starting designers, knowledge institutes, renowned designers, and large agencies, diverse and ground floor suits Amsterdam, even though we sometimes have to climb a steep staircase. GLUE gives a profile to Amsterdam design and wants to connect the sector better and make it more discoverable.
All participants have a dot on the map; we encourage them to organize something sensational and relevant during GLUE amsterdam connected by design.<br/> <br/>
        At GLUE me and my colleague Kris worked on making their routemap,
Through the routemap people could follow special routes selected through a filter of 4 routes this was all made by me and Kris and a WordPress plugin called Codepeople.</span>
            <br/> <p>This project was made and maintained for approximantly 3 months</p></>
,
    },
    {
        id: "Schrijfkoort_Schrijfattelier",
        title: "Schrijfkoort - Schrijfattelier",
        image: "./projects/SchrijfAttelier_schrijfkoorts/Schrijfattelier_home.png",
        github: "https://github.com/Streats22",
        images: [
            "/img/projects/SchrijfAttelier_schrijfkoorts/Schrijfattelier_winkel.png",
            "/img/projects/SchrijfAttelier_schrijfkoorts/Schrijfattelier_wm.png",
            "/img/projects/SchrijfAttelier_schrijfkoorts/Schrijfattelier_Part1.png",
            "/img/projects/SchrijfAttelier_schrijfkoorts/Schrijfattelier_Part2.png",
            "/img/projects/SchrijfAttelier_schrijfkoorts/Schrijfattelier_edu1.png",
            "/img/projects/SchrijfAttelier_schrijfkoorts/Schrijfatteliere_edu2.png",
            "/img/projects/SchrijfAttelier_schrijfkoorts/Schrijfkoorts.png"
        ],
        details: <span><b>Schrijfkoort/ Schrijfattelier</b> is een project opgezet door Jeanet van Omme<br /> Schrijfkoorts is een afspiegeling van 25 jaar Schrijfatelier..

Schrijfkoorts genereert per spel DUIZEND (10 x 10 x10)  schrijfopdrachten die je op een verrassende manier aanzetten tot schrijven.</span>,
        summary: <><span>Schrijfkoorts in het kort:
Schrijfkoorts is een afspiegeling van 25 jaar Schrijfatelier..
Schrijfkoorts genereert per spel DUIZEND (10 x 10 x10)  schrijfopdrachten die je op een verrassende manier aanzetten tot schrijven. Ieder woordenspel heeft een eigen thema, met altijd dezelfde opzet (wat, wie, hoe).
Schrijfkoorts helpt niet alleen individuele schrijvers aan nieuwe inspiratie, ook docenten Nederlands kunnen meteen met Schrijfkoorts aan de slag.
Het Schrijfatelier biedt trainingen aan voor individuele docenten en ook voor schoolteams..</span>
        <br/><br/>
            The project SchrijfAttelier and Schrijfkoorts is a project I maintained and edited the pages shown below are the pages I have worked on
            The site wasn't setup by me but I did maintain it and keep it working.
            There was no info about this Project on Github so this link will redirect you to my personal Github.
        </>,
    }

];

export const technologies = [
    {
        id: "react",
        technologyName: "React",
        projects: reactProjects,
        icon: "fa-react",
    },
    {
        id: "laravel",
        technologyName: "Laravel",
        projects: laravelProjects,
        icon: "fa-laravel",
    },
    {
        id: "wordpress",
        technologyName: "WordPress",
        projects: wordPressProjects,
        icon: "fa-wordpress-simple"
    },
];